<template>
  <div class="dataset-page">
    <!-- banner -->
    <div class="dataset-page_banner">
      <!-- title num -->
      <div class="data-list">
        <div class="data-item" v-for="(item, index) in titleList" :key="index">
          <div class="value">{{ item.value }}</div>
          <div class="name">{{ item.name }}</div>
          <div class="desc">{{ item.desc }}</div>
        </div>
      </div>
    </div>

    <!-- content -->
    <div class="dataset-page_type container">
      <el-tabs v-model="typeTabsActiveName" @tab-click="handleTypeClick">
        <!-- 数据集类型板块 -->
        <el-tab-pane label="类型" name="类型" disabled> </el-tab-pane>
        <el-tab-pane
          v-for="(item, index) in typeList"
          :label="item.label"
          :name="item.value"
          :key="index"
        >
          <div class="type_content">
            <el-tabs
              tab-position="left"
              class="left-tab"
              v-model="dimensionTabsActvieName"
              @tab-click="handleDimClick"
            >
              <el-tab-pane
                v-for="leftItem in dimensionList"
                :key="leftItem.EnglishName"
                :label="leftItem.ChineseName"
                :name="leftItem.ChineseName"
              >
                <div class="content-box">
                  <div class="header">
                    <div class="title">{{ leftItem.ChineseName }}</div>
                    <div class="desc">
                      {{ leftItem.ChineseIntro }}
                    </div>
                  </div>

                  <!-- 数据集 -->
                  <div v-if="datasetList.length">
                    <div
                      class="item"
                      v-for="(datasetItem, j) in datasetList"
                      :key="j"
                      @click="jumpDetail(datasetItem)"
                    >
                      <div class="item-title">{{ datasetItem.name }}</div>
                      <!-- <div class="item-label">
                      <el-tag type="success">开源</el-tag>
                      <el-tag type="info">标签A</el-tag>
                    </div> -->
                      <div class="item-desc">
                        {{ datasetItem.intro }}
                      </div>
                      <div class="item-lang">
                        语言:{{ datasetItem.language }}
                      </div>
                      <div class="item-source">
                        来源:{{ datasetItem.source }}
                      </div>
                      <div class="item-bottom d-flex">
                        <div class="left">查看详情</div>
                        <div class="right d-flex">
                          <!-- <div class="d-flex view-w">
                            <i class="el-icon-view"></i>
                            <div class="value">2024</div>
                          </div> -->
                          <div class="d-flex view-w">
                            <i class="el-icon-time"></i>
                            <div class="value">{{ datasetItem.time }}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div v-else class="">持续建设中...</div>
                </div>
              </el-tab-pane>
            </el-tabs>
          </div>
          <!-- 维度内容 -->
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import DimensionList from "@/assets/data/dimension/dimension.json";
import OriginDatasetList from "@/assets/data/dataset/dataset.json";

export default {
  data() {
    return {
      titleList: [
        { value: "79", name: "数据集数量", desc: "中文:38 英文：41" },
        { value: "68", name: "数据集评测量", desc: "中文:29 英文：32" },
        { value: "57", name: "维度", desc: "中文:24 英文：29" },
        { value: "3", name: "领域", desc: "中文:3 英文：3" },
      ],

      // typeList: ["全部", "代码", "机器人", "医疗", "金融", "硬件", "中医"],
      typeList: [
        {
          label: "全部",
          value: "all",
        },
        {
          label: "通用",
          value: "common",
        },
        // {
        //   label: "代码",
        //   value: "code",
        // },
        {
          label: "中医",
          value: "med",
        },
        {
          label: "金融",
          value: "finance",
        },
      ],
      dimensionList: DimensionList.dimensions, //维度列表
      datasetList: [], //对应维度的数据集
      originDatasetList: OriginDatasetList.dataset, //所有的数据集数据
      typeTabsActiveName: "all",
      dimensionTabsActvieName: "",
      dimensionIndex: 0, //维度下标
    };
  },
  created() {
    this.initData();
  },
  mounted() {},
  methods: {
    // 初始化数据
    initData() {
      this.dimensionList = [
        ...DimensionList.FIN,
        ...DimensionList.CTCMB,
        ...DimensionList.commonDataset,
      ];
      this.dimensionTabsActvieName = this.dimensionList[0].ChineseName;

      const name = this.dimensionList[0].EnglishName;
      this.datasetList = this.originDatasetList.filter(
        (item) => item.dimension === name
      );
    },
    handleTypeClick() {
      const value = this.typeTabsActiveName;
      if (value === "med") {
        this.dimensionIndex = 1;
      } else {
        this.dimensionIndex = 0;
      }
      this.getData();
    },
    handleDimClick(tab) {
      const index = tab.index;
      this.dimensionIndex = index;
      this.getData();
    },
    getData() {
      // 获取数据集列表逻辑
      const value = this.typeTabsActiveName;
      if (value === "med") {
        this.dimensionList = DimensionList.CTCMB;
      } else if (value === "common") {
        this.dimensionList = DimensionList.commonDataset;
      } else if (value === "finance") {
        this.dimensionList = DimensionList.FIN;
      } else {
        this.dimensionList = [
          ...DimensionList.FIN,
          ...DimensionList.CTCMB,
          ...DimensionList.commonDataset
        ];

        // 切换维度类型时
        // 默认激活选择第一个维度
      }
      this.dimensionTabsActvieName =
        this.dimensionList[this.dimensionIndex].ChineseName;

      // 获取数据集列表逻辑
      const name = this.dimensionList[this.dimensionIndex].EnglishName;
      // 匹配当前选择的维度中的EnglishName为数据集的维度dimension名称
      this.datasetList = this.originDatasetList.filter(
        (item) => item.dimension === name
      );
    },
    jumpDetail(item) {
      console.log("item.", item);
      this.$router.push({
        path: "/dataset-detail",
        query: {
          name: item.name,
        },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .el-tabs__item.is-disabled {
  // font-weight: bold;
  font-size: 14px;
  color: #4e5969;
}

::v-deep .el-tabs__item.is-disabled:after {
  content: "";
  position: absolute;
  left: auto;
  top: auto;
  bottom: 10px;
  right: 0;
  height: 52%;
  width: 1px;
  background-color: #ccc;
}

::v-deep .el-tabs--left .el-tabs__header.is-left {
  width: 160px;
}

.dataset-page {
  .dataset-page_banner {
    background: url("~@/assets/images/dataset/header_bg.png");
    height: 346px;
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center;

    .data-list {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 70px;
    }

    .data-item {
      color: #1d2129;
      flex: 1 0;
      .value {
        font-family: Open Sans, Open Sans;
        font-weight: 800;
        font-size: 56px;
        height: 56px;
        line-height: 56px;
        margin-bottom: 20px;
      }
      .name {
        font-weight: bold;
        font-size: 16px;
        height: 28px;
        line-height: 28px;
        color: #4e5969;
      }
      .desc {
        font-weight: 400;
        font-size: 14px;
        color: #1d2129;
        height: 22px;
      }
    }
    .data-item:not(:last-child) {
      border-right: 1px solid #999;
    }
  }

  .dataset-page_type {
    width: 100%;
    margin-bottom: 32px;

    .type_content {
      .content-box {
        .header {
          // height: 108px;
          background: #0e42d2;
          background: url("~@/assets/images/dataset/title_bg.png");
          background-size: cover;
          background-position: center;
          box-shadow: inset 0px -1px 0px 0px #e5e6e8;
          border-radius: 8px 8px 8px 8px;
          border: 1px solid #e5e6eb;
          text-align: left;
          padding: 16px 16px;
          margin-bottom: 16px;
          border-radius: 8px;

          .title {
            height: 28px;
            font-weight: 500;
            font-size: 20px;
            color: #ffffff;
            line-height: 28px;
            margin-bottom: 10px;
          }

          .desc {
            height: 22px;
            font-weight: 400;
            font-size: 14px;
            color: #ffffff;
            line-height: 22px;
          }
        }
        .item {
          padding: 16px;
          border-radius: 8px;
          border: 1px solid #e5e6eb;
          display: flex;
          flex-direction: column;
          align-items: start;
          width: 100%;
          margin-bottom: 16px;
          cursor: pointer;
          .item-title {
            height: 28px;
            font-weight: 500;
            font-size: 20px;
            color: #1d2129;
            line-height: 28px;
            margin-bottom: 8px;
          }
          .item-label {
            .el-tag {
              margin-right: 16px;
            }
          }
          .item-desc {
            font-weight: 400;
            font-size: 14px;
            color: #4e5969;
            text-align: left;
            line-height: 22px;
            margin-top: 16px;
            margin-bottom: 10px;
          }
          .item-lang,
          .item-source {
            height: 14px;
            font-weight: 400;
            font-size: 14px;
            color: #86909c;
            margin-bottom: 8px;
          }
          .item-bottom {
            width: 100%;
            margin-top: 16px;
            justify-content: space-between;
            font-size: 14px;
            color: #86909c;
            .left {
              height: 22px;
              font-family: PingFang SC, PingFang SC;
              font-weight: 500;
              font-size: 14px;
              color: #165dff;
              line-height: 22px;
              cursor: pointer;
            }
            .right {
              i {
                margin-right: 12px;
              }
              .view-w {
                margin-left: 36px;
              }
            }
          }
        }
        .item:hover {
          border: 1px solid #165dff;
        }
      }
    }
    .left-tab {
    }
  }
}
</style>
